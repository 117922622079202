import { CrmBaseTransformer } from 'common-module/endpoint';
import { CrmUser } from 'common-module/user';

import { UserModel } from './user.model';

export class UserResponseTransformer implements CrmBaseTransformer<UserModel> {
  transform(data: CrmUser & { id?: string }): UserModel {
    return { ...data, _id: data._id ?? data.id } as UserModel;
  }
}
