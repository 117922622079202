import { Injectable } from '@angular/core';
import { CrmLayoutProfileProvider } from 'common-module/layout-v2';

import { UserService } from '~/api/user/user.service';

@Injectable({ providedIn: 'root' })
export class LayoutProfileProvider extends CrmLayoutProfileProvider {
  protected override userService!: UserService;

  protected override handleProfileItem() {
    this.userService.user$.subscribe((user) => {
      this.router.navigate(['/users', user._id]).then(() => {});
    });
  }
}
