import { CrmAppConfiguration } from 'common-module/core/types';
import { CrmFilterConfig } from 'common-module/filter';
import {
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  CrmLayoutProfileComponent,
  crmLayoutSidebarOnly,
} from 'common-module/layout-v2';

import { LayoutProfileProvider } from '~/shared/crm/layout/layout-profile.provider';
import { environment } from '~/envs/environment';

export const appConfig: CrmAppConfiguration = {
  environment,
  modules: {
    layoutV2: {
      siderLeft: {
        ...crmLayoutSidebarOnly.siderLeft,
        collapseDisabled: true,
        theme: 'dark',
        fragments: [
          { key: 'logo', content: CrmLayoutLogoComponent },
          { key: 'menu', content: CrmLayoutMenuComponent },
          {
            key: 'profile',
            content: CrmLayoutProfileComponent,
            provider: LayoutProfileProvider,
          },
        ],
      },
      logo: {
        smallLogoPath: '/assets/images/logo-main.png',
        defaultLogoPath: '/assets/images/logo-main.png',
      },
      menu: {
        items: [
          {
            routeKey: 'users',
            key: 'menu.users',
            icon: 'profile',
          },
        ],
      },
      profile: {
        dropdownPlacement: 'right',
        dropdownIcon: 'down',
        dropdownIconVisible: 'right',
      },
    },
    filter: {
      actions: {
        clear: {
          enabled: false,
        },
      },
    } as CrmFilterConfig,
  },
};
